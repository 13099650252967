import Header from "../header";

import roadmap from "../../assets/img/roadmap.png";

import point_1 from "../../assets/img/point1.png";
import point_2 from "../../assets/img/point2.png";
import point_3 from "../../assets/img/point3.png";
import point_4 from "../../assets/img/point4.png";
import point_5 from "../../assets/img/point5.png";

import Manifesto from "../../assets/Manifesto.pdf";

const RoadmapImg = () => {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: 0 }}>
        <img src={roadmap} alt="" className="img-fluid" />
      </div>
    </div>
  );
};

function Section_1({ handleShow }) {
  return (
    <div className="section-1">
      <Header handleShow={handleShow} />
      <div className="container">
        <div className="row ">
          <div className="col-lg-6 col-12 row1">
            <h1>
              Our <span className="text-green">Roadmap</span> For Pakistan
            </h1>
            <p className="text">
              Our roadmap outlines how we plan to improve Pakistan and bring
              about positive change.
            </p>
          </div>
          <div className="col-lg-6 col-12 roadmap">
            {/* <RoadmapImg /> */}
            <img src={roadmap} alt="" className="img-fluid" />
            <div className="point_1">
              <a href={Manifesto} target="_blank">
                <img src={point_1} alt="point_1" className="img-fluid" />
              </a>
            </div>
            <div className="point_2">
              <a href={Manifesto} target="_blank">
                <img src={point_2} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="point_3">
              <a href={Manifesto} target="_blank">
                <img src={point_3} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="point_4">
              <a href={Manifesto} target="_blank">
                <img src={point_4} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="point_5">
              <a href={Manifesto} target="_blank">
                <img src={point_5} alt="" className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="section-1-end">
        <div className="container-fluid">
          <div className="row text-center pt-3 pb-3">
            <h4>
              <span>LET'S JOIN </span>
              <span> - </span>
              <span>TECHNOLOGY MOVEMENT PAKISTAN</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section_1;
