import Manifesto from "../../assets/Manifesto.pdf";

function ImgText(props) {
  return (
    <>
      <div className="img-2-text">
        <div className="row">
          <div className="col-md-6 part-1">
            <img src={props.image} alt="img" className="img-fluid" />
          </div>
          <div className="col-md-6 part-2">
            <h1>{props.heading}</h1>
            <p>{props.text}</p>
            <a className="btn" target="_blank" href={Manifesto}>
              {" "}
              Read More
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImgText;
