import Manifesto from "../../assets/Manifesto.pdf";
import nuclearPDF from "../../assets/Nuclear_AI_Compute_Business_Model.pdf";

function TextImg(props) {
  return (
    <>
      <div className="img-2-text">
        <div className="row row_flex">
          <div className="col-md-6 part-1">
            <h1>{props.heading}</h1>
            <p>{props.text}</p>
            <a
              className="btn"
              href={props?.link1 == "nuclear" ? nuclearPDF : Manifesto}
              target="_blank"
            >
              {" "}
              Read More
            </a>
          </div>
          <div className="col-md-6 part-2">
            <img src={props.image} alt="img" className="img-fluid" />
          </div>
        </div>
      </div>
    </>
  );
}

export default TextImg;
