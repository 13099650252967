import { useEffect, useState } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import { MobileAppSection } from "../components/mobileAppSection/mobileAppSection";
import Section_1 from "../components/section_1/section_1";
import Section_2 from "../components/section_2/1-section_2";
import Section_3 from "../components/section_3/section_3";
import Section_4 from "../components/section_4/section_4";
import video1 from "../assets/video/video1.mp4";
import { mobileAnimate } from "../assets/js/mobileAnimation";
import { Modal } from "react-bootstrap";

function Home() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    mobileAnimate();
    // setShow(true)
    // playVideo()
  }, []);

  // const playVideo = ()=>{
  // 	try {
  // 			setTimeout(() => {
  // 				document.getElementById("vid").muted = false;
  // 			}, 1000);
  // 	} catch (error) {
  // 		console.log(error);
  // 	}
  // }

  return (
    <>
      <div className="App">
        {/* <Header /> */}
        <Section_1 handleShow={handleShow} />
        <Section_2 />
        {/* <Section_3 /> */}
        <MobileAppSection />
        <Section_4 />
        <Footer />
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
          <h2 className="font-bold">OUR OBJECTIVE</h2>
        </Modal.Header>
        <Modal.Body>
          <video
            style={{ maxWidth: "100%", width: "100%" }}
            controls
            loop
            id="vid"
          >
            <source src={video1} type="video/mp4" />
            {/* <source type="video/youtube" src="https://www.youtube.com/watch?v=34yeX2bGxGo" /> */}
            <source src="mov_bbb.ogg" type="video/ogg" />
            Your browser does not support HTML video.
          </video>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Home;
