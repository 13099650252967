import ImgText from "./2-img-text";
import TextImg from "./3-text-img";

import img1 from "../../assets/img/sec1img.png";
import img2 from "../../assets/img/sec2img.png";
import img3 from "../../assets/img/sec3img.png";
import img4 from "../../assets/img/sec4img.png";
import img5 from "../../assets/img/sec5img.png";
import img6 from "../../assets/img/sec6img.png";
import img7 from "../../assets/img/sec7img.png";
import img8 from "../../assets/img/sec8img.png";

import img9 from "../../assets/img/Rural.png";
import img10 from "../../assets/img/Minor.png";
import img11 from "../../assets/img/Madr.png";
import img12 from "../../assets/img/wome.png";
import img13 from "../../assets/img/agriai.png";
import img14 from "../../assets/img/humand.jpg";
import img15 from "../../assets/img/tradei.png";
import img16 from "../../assets/img/freelancer.png";
import img17 from "../../assets/img/educ.png";
import img18 from "../../assets/img/nuclearHub.png";

import icon1 from "../../assets/img/icon1.png";
import icon2 from "../../assets/img/icon2.png";

import Manifesto from "../../assets/Manifesto.pdf";
import Constitution from "../../assets/Constitution.pdf";

import waqar_zaka from "../../assets/img/waqarzaka.png";
import signature from "../../assets/img/signature.png";
import React, { useState } from "react";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  console.log(text.toString().slice(0, 5));
  return (
    <p className="text">
      {isReadMore ? text.toString().slice(0, 860) : text}
      <span
        onClick={toggleReadMore}
        style={{ cursor: "pointer" }}
        className="read-or-hide"
      >
        <strong>{isReadMore ? "...  READ MORE" : " SHOW LESS"}</strong>
      </span>
    </p>
  );
};

function Section_2() {
  return (
    <>
      <div className="section_2" id="mission">
        <div className="container">
          <div className="row text-center">
            <div className="head">
              <h1>Our Mission & Vision</h1>
              <p>
                Welcome to Technology Movement Pakistan, a dynamic political
                party dedicated to modernizing outdated policies that hinder
                Pakistan's revenue. We are passionate about revolutionizing
                policy-making and implementation methods, guided by a
                comprehensive Policy Roadmap for meaningful change. Join us in
                shaping Pakistan's future by contributing to our agenda of
                revenue-generating policies. Together, we can drive
                transformative progress. Scroll down to discover our plans for
                Pakistan.
              </p>
            </div>
          </div>

          <TextImg
            image={img18}
            heading="Nuclear Power for a Global AI Compute Hub"
            text="A billion-dollar idea. Our mission is to position Pakistan as a global AI leader by leveraging nuclear energy to power cutting-edge AI infrastructure. We will develop a world-class AI compute hub in Gwadar, providing affordable, high-speed AI processing for financial markets, crypto trading, and global tech firms. This initiative will attract billions in investment, create high-tech jobs, and solidify Pakistan’s place in the digital economy. Through strategic partnerships with China, UAE, and top AI firms, we will ensure Pakistan leads the next technological revolution. Pakistan will not just consume AI—we will power it. Together, let's make our nation the driving force of the world’s AI economy."
            link1="nuclear"
          />
          <ImgText
            image={img9}
            heading="Focus on Rural Areas"
            text="Our mission is to uplift rural areas through the implementation of policies that harness the potential of AI-based tools and provide comprehensive training for farmers. We aspire to revolutionize farming, making it a vibrant trend that captivates the imagination of the masses. Through media film-style shows, we will celebrate farmers as celebrities, honoring their invaluable contributions and inspiring others to pursue farming as a noble profession. Moreover, we are committed to ensuring justice for poor villagers by holding the police accountable for any injustices they may face. Together, let's build a future where rural communities thrive, and farmers are recognized as the true heroes of our nation."
          />

          <TextImg
            image={img11}
            heading="Focus on Madras and Mosques"
            text="Our commitment extends to transforming Mosques into interactive spaces for social gatherings. Through a dedicated campaign, we will enhance the experience by training Islamic scholars to conduct peaceful debates and discussions. Our vision is to develop a modern Islamic-based curriculum that empowers students, providing an alternative to attending Western-style schools. By focusing on practical skills, we aim to create opportunities for Madras students to earn a livelihood without relying on Western degrees. To ensure the integrity of Madras activities, we will establish consistent monitoring and oversight. Our goal is to nurture a Muslim generation that embraces a mindset of healthy debate, rejecting the misuse of Islam for personal egos or conflicts. Together, let's pave the way for a harmonious future rooted in knowledge and understanding"
          />

          <ImgText
            image={img12}
            heading="Woman Workforce"
            text="Around 48.54 percent of Pakistan's population consists of women. If this fraction were given access to enough
                   resources, they would make significant contributions to the country's economic growth. Workfrom-home opportunities
                   will be made available in both rural areas and major cities with the aid of efficient awareness campaigns and
                   self-learning platforms."
          />

          <TextImg
            image={img13}
            heading="Agriculture with AI"
            text="AI can help farmers make data-driven decisions by collecting data from various sources such as weather patterns, soil health, crop maturity, and pest infestation. Machine learning algorithms can analyze this information to provide farmers with customized insights tailored to their specific needs. This can help farmers optimize their crop yield, reduce input costs, and increase profitability. Additionally, AI can also help address environmental concerns by minimizing water usage and reducing the use of harmful pesticides and fertilizers. "
          />

          <ImgText
            image={img16}
            heading="Freelancers"
            text="One of our primary goals would be to enhance the freelancing sector in Pakistan. In addition to implementing
                   cutting-edge payment options, we plan to devise user-friendly licensing and payment gateway policies. For this reason,
                   we're forming the first-ever Pakistan Cryptocurrency Board to usher in a regulated market for digital money. As a result
                   of the introduction of crypto mining services powered by hydropower in the country's northern regions, a new source of
                   money will emerge"
          />

          <TextImg
            image={img10}
            heading="Campaigns for Minorities"
            text="We will utilize psychology-based social media campaigns to prevent any attack on minorities, be it loss of life or forced Muslim marriages, and to foster understanding and tolerance within society. To promote a culture of healthy debate and respect for diverse beliefs, we will launch media campaigns aimed at educating Pakistanis."
          />

          <ImgText
            image={img14}
            heading="Human Development"
            text="Reforms will be made so that clean and hygienic food and water is in reach of all.
                   Until financial independence is gained deserving masses should be provided by basic human
                   rights including the right to skill development. Tech offers enormous opportunities to gain
                   self-sufficiency. Every person according to his talent, skills and taste can generate a
                   revenue not just for their own self but for the country by using digital resources. This
                   will make Pakistan inclusive and upfront internationally which will invite means of investment"
          />

          {/*}
               <TextImg
                   image={img4}
                   heading="Systematic Educational Support"
                   text="Right to internet should be made common. An average Pakistani uses
                   only 5% of the internet which provides no gain to them or the society. By
                   developing smart schools, villages and cities, shortages of faculty for
                   schools, workshops and small-scale industries will be eliminated. We recognize the pivotal role of education in laying the foundation for the development of a progressive and prosperous society."
               />

*/}

          <TextImg
            image={img17}
            heading="Education For All"
            text="As the Technology Movement Pakistan, we firmly believe that education is a fundamental right that must be accessible to everyone, regardless of their social, economic or geographic backgrounds. As a political party focused on advancing Pakistan's technological landscape, the Technology Movement Pakistan firmly believes in the power of progress through English language education. We recognize that English has become the global language of communication and technology, making it an indispensable tool for success in today's fast-paced world. We vow to promote English language proficiency across all levels of education and provide access to high-quality language learning resources for all students and teachers. "
          />

          <ImgText
            image={img15}
            heading="Trade And Industry"
            text="Small scale industries will be offered constant support by this movement.
                   Trading with neighboring countries will be cordial and easy. This will make
                   sure that legal ways of importing and exporting are in reach for private
                   firms. Cordial trading incentives with neighboring countries will create
                   strong roots for a better and independent foreign policy in the long run."
          />

          {/*ss
              <TextImg
                   image={img8}
                   heading="Minority Rights"
                   text="As a party that stands at the forefront of change through technology, we firmly believe that every citizen of Pakistan deserves equal rights regardless of their ethnicity, religion, gender, or sexual orientation. We are committed to upholding the protection and promotion of minority rights across the country. We envision a country where there are no barriers to good citizenship, and where every Pakistani can thrive irrespective of their identity. We urge all like-minded individuals and groups to join hands with us in improving the human condition across the country. Together, we will work towards a Pakistan that is inclusive, progressive, and just for all its citizens."
               />






              <ImgText
                   image={img2}
                   heading="Digital Economy"
                   text="Owning Crypto mines and earning digital currency is the new wave of development,
                   this will strengthen our economy and stabilize our currency. Moreover, by replacing virtual
                   money with cash climate will be improved, combustion and land erosion will be reduced and
                   Pakistan will be able to take a step ahead, merging with changing world norms. Virtual money
                   will be easy to invest, exchange and transact. By using these incentives Zaka claims to open
                   new chapters of transparency by computerizing incomes, losses and fiscal gains."
                 /> */}

          <div className="row text-center" id="manifesto">
            <div className="head mb-5">
              <h1>Our Manifesto & Constitution</h1>
            </div>
            <div className="col-md-6 mb-5">
              <div className="card h-100">
                <div className="content">
                  <img src={icon1} alt="icon" className="img-fluid" />
                  <h3>Manifesto Of TMP</h3>
                  <p>
                    We wants to bring Pakistan at par globally by legalizing
                    digital currency, standardizing freelancing and making
                    electronic media, sports and applications in easy reach for
                    all.
                  </p>
                  <a href={Manifesto} target="_blank">
                    <button className="btn">Manifesto</button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-5" id="constitution">
              <div className="card h-100">
                <div className="content">
                  <img src={icon2} alt="icon" className="img-fluid" />
                  <h3>Constitution OF TMP</h3>
                  <p>
                    We have established a political party to mobilize people to
                    take Pakistan from old and traditional stereotypical
                    methods, and to set it on the path of Science, Technology,
                    digitalization and prosperity.
                  </p>
                  <a href={Constitution} target="_blank">
                    <button className="btn">Constitution</button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 part-1">
              <div className="head">
                <img src={waqar_zaka} alt="img" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6 part-2">
              <div className="head">
                <h1>
                  Meet <span>Waqar Zaka</span>
                </h1>
              </div>
              <ReadMore>
                Waqar Zaka, did his Matric from Karachi’s top school, B.V.S
                Parsi High School, then Admajee science college, did his Civil
                Engineering from N.E.D University, his remarkable achievements
                speak volumes about his leadership and dedication. Time and time
                again, he has demonstrated his belief that a leader should lead
                from the front, rather than just calling for protests. Waqar
                Zaka's extraordinary courage and dedication are exemplified by
                his relentless pursuit of justice for the marginalized and
                oppressed. As a prominent influencer with a vast social media
                network, he utilizes his platform to raise awareness about
                modern technology and its implications. However, his impact goes
                far beyond content creation. Waqar Zaka is renowned for his
                unwavering support for rape victims, acid attack survivors,
                women facing cyberbullying, and, above all, his tireless
                advocacy for minority rights. Not content with merely raising
                awareness, he takes tangible action by providing practical
                assistance to victims through the legal system. One of his most
                audacious endeavors involved embarking on a solitary journey to
                assist impoverished Hindu and Christian sweepers in a town.
                Waqar Zaka's fearless advocacy for the rights of religious
                minorities in Dunga Bunga town is truly commendable. His
                unyielding efforts led to the dismissal of false blasphemy
                charges and the successful attainment of justice for the
                oppressed.{" "}
                <a href="https://twitter.com/ZakaWaqar/status/915495019523575808?s=20">
                  Link{" "}
                </a>
                Waqar Zaka's selfless and daring actions demonstrate his deep
                commitment to upholding the principles of equality, justice, and
                compassion. He is a true champion for the marginalized, leaving
                an indelible impact on the lives of those he fights for. Back in
                2016, Waqar Zaka envisioned a tremendous opportunity for
                Pakistan's economic growth. At a time when Bitcoin was valued at
                a mere $268, he proposed the installation of Bitcoin mining
                operations powered by hydroelectricity in the northern regions
                of the country. To showcase the feasibility of his idea, he
                personally invested and established a substantial cryptocurrency
                mining farm utilizing hydroelectric power. Waqar Zaka strongly
                advocated for the Pakistani government to embrace and welcome
                crypto miners from around the world, urging them to set up their
                mining operations exclusively in the northern areas, leveraging
                the abundant hydroelectric resources available. Regrettably, the
                government did not seize this opportunity, failing to recognize
                the potential it held. However, recent developments have
                vindicated Waqar Zaka's foresight. In 2023, it came to light
                that the Government of Bhutan had clandestinely engaged in
                Bitcoin mining using the same hydroelectric power theory
                initially proposed by Waqar Zaka.{" "}
                <a href="https://propakistani.pk/2021/10/04/heres-how-a-mainstream-influencer-helped-pakistan-lead-the-crypto-game/">
                  {" "}
                  Link{" "}
                </a>{" "}
                Furthermore, the Kazakhstan Government experienced substantial
                financial gains, generating a staggering $7 million in a single
                year solely from crypto miners, primarily through tax revenue.{" "}
                <a href="https://news.bitcoin.com/crypto-miners-pay-kazakhstan-7-million-in-taxes-amid-uncertain-future-for-sector/">
                  {" "}
                  Link{" "}
                </a>
                This missed opportunity highlights the immense potential
                Pakistan failed to capitalize on, as other nations embraced
                cryptocurrency mining as a lucrative source of revenue. Waqar
                Zaka's early predictions and practical demonstration of this
                concept serve as a testament to his economic foresight and
                innovative thinking. Particularly noteworthy is his fearless
                pursuit to unban crypto, where he valiantly confronted the
                Central Bank and prominent financial authorities for an
                impressive duration of two years. It is worth highlighting that
                Waqar Zaka took on this complex legal battle without the support
                of a lawyer, as no one was willing to tackle the intricate
                constitutional implications associated with cryptocurrency in
                Pakistan. Undeterred, he fought tirelessly, presenting
                compelling legal arguments against influential figures such as
                the Central Bank's Deputy Governor, representatives from the
                Financial Ministry, the Attorney General, and a lawyer from the
                Federal Investigation Authority. Waqar Zaka's exceptional
                advocacy not only swayed the judges but also earned him their
                explicit appreciation, as stated in the court order. This
                monumental victory stands as a testament to Waqar Zaka's legal
                prowess and his unwavering determination to champion the cause.
                When e-sports faced a ban imposed by the government, it was
                Waqar Zaka who spearheaded the campaign against this decision.
                Despite calls for on-ground protests, he wisely prioritized
                public safety during the COVID-19 pandemic. Instead, he filed a
                court case and personally appeared in court, eventually becoming
                a member of the Pakistan Telecommunication Authorities
                Commission. Through his legal arguments, he successfully
                advocated for the allowance of the popular e-sport game PUBG,
                all achieved through his solo efforts. It is important to
                highlight that as a youth icon, Waqar Zaka could have easily
                mobilized his followers for street protests. However, he
                consciously chose a different path, avoiding public gatherings
                and investing his own time and resources to navigate the legal
                system. This exemplifies his responsible leadership and
                commitment to achieving results while keeping the public's
                well-being in mind.{" "}
                <a href="https://dailytimes.com.pk/635448/youtuber-waqar-zaka-challenges-pubg-ban-in-court/">
                  {" "}
                  Link{" "}
                </a>
                Waqar Zaka's genuine dedication to creating a positive impact is
                evident in his approach towards social issues. He doesn't simply
                seek quick views or fame by addressing trending topics, but
                rather focuses on bringing about meaningful change. A prime
                example of this is his tireless effort to update the outdated
                computer book course, which had remained stagnant since 1997.
                Recognizing the significance of modernizing the educational
                system, Waqar Zaka took the initiative to file a petition in the
                Sindh High Court.{" "}
                <a href="https://www.bolnews.com/trending/2020/02/massive-achievement-for-waqar-zaka-as-sindh-board-announced-to-update-the-curriculum">
                  {" "}
                  Link{" "}
                </a>{" "}
                His determined actions exemplify his sincere commitment to
                improving the learning experience for students. As a testament
                to his efforts, the court responded by issuing an order to the
                education minister, compelling the update of the computer book.
                Waqar Zaka's endeavor underscores his true dedication to
                reforming the educational system and highlights his unwavering
                belief in the power of progress. Through his proactive stance,
                he is actively contributing to the betterment of education in
                Pakistan.
              </ReadMore>

              <img src={signature} alt="img" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section_2;
